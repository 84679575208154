<template>
  <v-container
    id="regular-tables-view"
    fluid
    tag="section"
  >
    <snack-bar ref="snackBar" />
    <v-dialog
      v-model="deleteRecordCheckDialog"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Privacy Policy
        </v-card-title>
        <v-card-text>
          <h4 class="mt-1">
            Type DELETED to confirm you want delete this record.
          </h4>
          <v-text-field
            v-model="deleteRecordConfirm"
          >
          </v-text-field>
          <v-row>
            <v-alert
              :value="deleteAlert.alert"
              :type="deleteAlert.type"
              dense
            >
              {{ deleteAlert.message }}
            </v-alert>
            <v-spacer></v-spacer>
            <v-btn
              @click="processDelete"
              text
            >
              Confirm
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <list-detail-show
      ref="listDetailShow"
      :showData="configurationListDetail"
    >
    </list-detail-show>
    <testing-record
      @updatedRecord="loadEquipmentRecordsData"
      ref="testingRecord"
    >
    </testing-record>
    <v-data-table
      :headers="equipmentRecordsHeader"
      :items="equipmentRecordsData"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>Server List</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-col
            cols="3"
            class="mt-2"
          >
            <v-text-field
              v-model="keyWord"
              placeholder="Search PN or SN"
              color="secondary"
              hide-details
              style="max-width: 220px"
            >
              <template
                v-if="$vuetify.breakpoint.mdAndUp"
                v-slot:append-outer
              >
                <v-btn
                  class="mt-n2 ml-n2"
                  fab
                  small
                  elevation="2"
                  height="44"
                  width="44"
                  @click="loadEquipmentRecordsData()"
                >
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-btn
            min-width="120px"
            @click="recordComponentTrigger"
          >
            Create Record
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="viewRecord(item)"
        >
          mdi-file-find
        </v-icon>
        <v-icon
          small
          class="mr-2"
          @click="editRecord(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="deleteRecord(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <template>
      <div class="text-center">
        <v-pagination
          v-model="pageInfo.current"
          :length="pageInfo.total"
          @input="changePageRequest"
        ></v-pagination>
      </div>
    </template>
  </v-container>
</template>

<script>
  import SnackBar from '@/components/app/SnackBar'
  import TestingRecord from '@/components/equipment/TestingRecord'
  import equipmentTestingRecordApi from '@/api/equipment-testing-record'
  import configurationListApi from '@/api/equipment-configuration-list'
  import ListDetailShow from '@/components/configuration/ListDetailShow'
  import store from '@/store'
  export default {
    name: 'EquipmentRecord',
    components: { SnackBar, TestingRecord, ListDetailShow },
    data () {
      return {
        deleteRecordCheckDialog: false,
        deleteRecordConfirm: '',
        requestDeleteRecordID: -1,
        deleteAlert: {
          alert: false,
          type: '',
          message: '',
        },
        equipmentRecordsHeader: [
          {
            text: 'ID',
            align: 'start',
            sortable: false,
            value: 'id',
          },
          { text: 'PN', value: 'item.part_number' },
          { text: 'SN', value: 'serial_number' },
          { text: 'Type', value: 'item.item_type.name' },
          { text: 'PO', value: 'item_receipt.po_number' },
          { text: 'Note', value: 'note' },
          { text: 'Status', value: 'equipment_status.name' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        equipmentRecordsData: [],
        keyWord: '',
        configurationListDetail: {},
        pageInfo: {
          total: 1,
          current: 1,
        },
      }
    },
    created () {
      this.loadEquipmentRecordsData()
    },
    methods: {
      saveRecord () {
        this.$refs.testingRecord.saveFinalRecord()
      },
      recordComponentTrigger () {
        this.$refs.testingRecord.createRecordDialog = true
      },
      loadEquipmentRecordsData () {
        equipmentTestingRecordApi.index(this.keyWord).then(response => {
          this.equipmentRecordsData = response.data.data.data
          this.pageInfo.total = response.data.data.last_page
          this.pageInfo.current = response.data.data.current_page
        }).catch(error => {
          store.set('message/storeMessage', {
            color: 'error',
            text: error,
            timeout: 3000,
          })
          this.$refs.snackBar.activeStatusInfo()
        })
      },
      viewRecord (item) {
        configurationListApi.show(item.equipment_configuration_list_id).then(response => {
          if (response.data.code === 200) {
            this.configurationListDetail = response.data.data[0]
            this.configurationListDetail.fullModelName = this.configurationListDetail.equipment_model_id.brand + '-' +
              this.configurationListDetail.equipment_model_id.generation + '-' +
              this.configurationListDetail.equipment_model_id.name
            this.$refs.listDetailShow.configurationListDetailDialog = true
          }
        }).catch(error => {
          store.set('message/storeMessage', {
            color: 'error',
            text: error,
            timeout: 3000,
          })
          this.$refs.snackBar.activeStatusInfo()
        })
      },
      editRecord (item) {
        this.$refs.testingRecord.loadRecord(item.id)
      },
      deleteRecord (item) {
        this.deleteRecordConfirm = ''
        this.deleteRecordCheckDialog = true
        this.requestDeleteRecordID = item.id
      },
      processDelete () {
        if (this.deleteRecordConfirm === 'DELETED') {
          this.deleteRecordCheckDialog = false
          equipmentTestingRecordApi.destroy(this.requestDeleteRecordID).finally(() => {
            this.loadEquipmentRecordsData()
          })
        } else {
          this.deleteAlert.message = 'Please type DELETED to confirm you want delete this record'
          this.deleteAlert.type = 'error'
          this.deleteAlert.alert = true
        }
      },
      changePageRequest (page) {
        equipmentTestingRecordApi.pageChange(page, this.keyWord).then(response => {
          this.equipmentRecordsData = response.data.data.data
          this.pageInfo.total = response.data.data.last_page
          this.pageInfo.current = response.data.data.current_page
        })
      },
    },
  }
</script>

<style scoped>

</style>
